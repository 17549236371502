import { Navigation as PolarisNav } from "@shopify/polaris";
import {
  AdjustIcon,
  ArchiveIcon,
  BookIcon,
  CalendarIcon,
  ChatIcon,
  ClipboardIcon,
  EmailIcon,
  ImagesIcon,
  LockIcon,
  PlusCircleIcon,
  QuestionCircleIcon,
  SettingsIcon,
  WandIcon,
} from "@shopify/polaris-icons";
import { removeNulls } from "./utils/shared";

interface ShowRecordMgmt {
  showSearch?: boolean;
  showAdd?: boolean;
  showBulkDash?: boolean;
  showBoostMgr?: boolean;
}

interface BirthRecordMgmt extends ShowRecordMgmt {
  showAnnualRelease?: boolean;
}

interface NavigationProps {
  showAuth?: boolean;
  showEmailAutos?: boolean;
  showAdminRoleMgmt?: boolean;
  birthRecords?: BirthRecordMgmt;
  deathRecords?: ShowRecordMgmt;
  goldStarRecords?: ShowRecordMgmt;
  vetsGraves?: ShowRecordMgmt;
  census?: ShowRecordMgmt;
  collections?: {
    showSearch?: boolean;
    showBoostMgr?: boolean;
    showSearchConfig?: boolean;
  };
  findAids?: ShowRecordMgmt;
  comments?: boolean;
}

export default function Navigation(props: NavigationProps) {
  const birthRecordsNav = props.birthRecords
    ? removeNulls([
        props.birthRecords.showSearch
          ? {
              url: "/rma/birth-records",
              label: "Search birth records",
              icon: ArchiveIcon,
            }
          : null,
        props.birthRecords.showAdd
          ? {
              url: "/rma/birth-records/add",
              label: "Add birth record",
              icon: PlusCircleIcon,
            }
          : null,
        props.birthRecords.showBoostMgr
          ? {
              url: "/rma/birth-records/boosts",
              label: "Manage weights",
              icon: AdjustIcon,
            }
          : null,
        props.birthRecords.showBulkDash
          ? {
              url: "/rma/birth-records/bulk/dash",
              label: "Bulk Editing Area",
              icon: WandIcon,
            }
          : null,
        props.birthRecords.showAnnualRelease
          ? {
              url: "/rma/birth-records/annual-release",
              label: "Annual Record Release",
              icon: CalendarIcon,
            }
          : null,
      ])
    : [];

  const birthRecords = birthRecordsNav.length > 0 && (
    <PolarisNav.Section
      separator
      title="Birth Records"
      items={birthRecordsNav}
    />
  );

  const deathRecordsNav = props.deathRecords
    ? removeNulls([
        props.deathRecords.showSearch
          ? {
              url: "/rma/death-records",
              label: "Search death records",
              icon: ArchiveIcon,
            }
          : null,
        props.deathRecords.showAdd
          ? {
              url: "/rma/death-records/add",
              label: "Add death record",
              icon: PlusCircleIcon,
            }
          : null,
        props.deathRecords.showBoostMgr
          ? {
              url: "/rma/death-records/boosts",
              label: "Manage weights",
              icon: AdjustIcon,
            }
          : null,
        props.deathRecords.showBulkDash
          ? {
              url: "/rma/death-records/bulk/dash",
              label: "Bulk Editing Area",
              icon: WandIcon,
            }
          : null,
      ])
    : [];

  const deathRecords = deathRecordsNav.length > 0 && (
    <PolarisNav.Section
      separator
      title="Death Records"
      items={deathRecordsNav}
    />
  );

  const goldStarRecordsNav = props.goldStarRecords
    ? removeNulls([
        props.goldStarRecords.showSearch
          ? {
              url: "/rma/gold-star",
              label: "Search gold star roll",
              icon: ArchiveIcon,
            }
          : null,
        props.goldStarRecords.showAdd
          ? {
              url: "/rma/gold-star/add",
              label: "Add gold star roll record",
              icon: PlusCircleIcon,
            }
          : null,
        props.goldStarRecords.showBoostMgr
          ? {
              url: "/rma/gold-star/boosts",
              label: "Manage weights",
              icon: AdjustIcon,
            }
          : null,
      ])
    : [];

  const goldStarRecords = goldStarRecordsNav.length > 0 && (
    <PolarisNav.Section
      separator
      title="Gold Star Roll"
      items={goldStarRecordsNav}
    />
  );

  const vetsGravesNav = props.vetsGraves
    ? removeNulls([
        props.vetsGraves.showSearch
          ? {
              url: "/rma/vets-graves",
              label: "Search veterans graves",
              icon: ArchiveIcon,
            }
          : null,
        props.vetsGraves.showAdd
          ? {
              url: "/rma/vets-graves/add",
              label: "Add vets grave record",
              icon: PlusCircleIcon,
            }
          : null,
        props.vetsGraves.showBoostMgr
          ? {
              url: "/rma/vets-graves/boosts",
              label: "Manage weights",
              icon: AdjustIcon,
            }
          : null,
        props.vetsGraves.showSearch
          ? {
              label: "Vets graves finding aid",
              icon: ClipboardIcon,
              onClick: () =>
                window.open("http://www2.mnhs.org/library/findaids/va001.xml"),
            }
          : null,
      ])
    : [];

  const vetsGraveRecords = vetsGravesNav.length > 0 && (
    <PolarisNav.Section
      separator
      title="Veterans Graves"
      items={vetsGravesNav}
    />
  );

  const censusNav = props.census
    ? removeNulls([
        props.census.showSearch
          ? {
              url: "/rma/census",
              label: "Search census",
              icon: ArchiveIcon,
            }
          : null,
        props.census.showAdd
          ? {
              url: "/rma/census/add",
              label: "Add census record",
              icon: PlusCircleIcon,
            }
          : null,

        props.census.showBoostMgr
          ? {
              url: "/rma/census/boosts",
              label: "Manage weights",
              icon: AdjustIcon,
            }
          : null,
        props.census.showSearch
          ? {
              url: "/rma/census/page",
              label: "Search census pages",
              icon: BookIcon,
            }
          : null,
        props.census.showAdd
          ? {
              url: "/rma/census/page/add",
              label: "Add census page",
              icon: PlusCircleIcon,
            }
          : null,
        props.census.showBoostMgr
          ? {
              url: "/rma/census/page/boosts",
              label: "Manage page weights",
              icon: AdjustIcon,
            }
          : null,
      ])
    : [];

  const censusRecords = censusNav.length > 0 && (
    <PolarisNav.Section separator title="Census" items={censusNav} />
  );

  const collectionsNav = props.collections
    ? removeNulls([
        props.collections.showSearch
          ? {
              url: "/rma/collections/catalogue-items",
              label: "Search cms",
              icon: ArchiveIcon,
            }
          : null,
        props.collections.showBoostMgr
          ? {
              url: "/rma/collections/catalogue-items/boosts",
              label: "Manage weights",
              icon: AdjustIcon,
            }
          : null,
        props.collections.showSearchConfig
          ? {
              url: "/rma/collections/search-config",
              label: "Search settings",
              icon: SettingsIcon,
            }
          : null,
        props.collections.showSearchConfig
          ? {
              url: "/rma/collections/catalogue-items/image-config",
              label: "Image resolution settings",
              icon: ImagesIcon,
            }
          : null,
      ])
    : [];

  const collections = collectionsNav.length > 0 && (
    <PolarisNav.Section separator title="Collections" items={collectionsNav} />
  );

  const findAidsNav = props.findAids
    ? removeNulls([
        props.findAids.showSearch
          ? {
              url: "/rma/finding-aids",
              label: "Finding Aids",
              icon: ArchiveIcon,
            }
          : null,
        props.findAids.showAdd
          ? {
              url: "/rma/finding-aids/add",
              label: "Add finding aid",
              icon: PlusCircleIcon,
            }
          : null,
      ])
    : [];

  const findAids = findAidsNav.length > 0 && (
    <PolarisNav.Section separator title="Finding Aids" items={findAidsNav} />
  );

  const adminNav = removeNulls([
    props.showAuth
      ? {
          url: "/rma/auth/",
          label: "Manage authorization",
          icon: LockIcon,
        }
      : null,
    props.showEmailAutos
      ? {
          url: "/rma/admin/email-autos",
          label: "Manage email automations",
          icon: EmailIcon,
        }
      : null,
    props.showAdminRoleMgmt
      ? {
          url: "/rma/auth/roles",
          label: "Role Management",
          icon: LockIcon,
        }
      : null,
    props.comments
      ? {
          url: "/rma/comments",
          label: "Comments Feed",
          icon: ChatIcon,
        }
      : null,
    props.comments
      ? {
          url: "/rma/comments/admin",
          label: "Comments Admin",
          icon: SettingsIcon,
        }
      : null,
  ]);

  const admin = adminNav.length > 0 && (
    <PolarisNav.Section separator title="Admin" items={adminNav} />
  );

  const helpNav = removeNulls([
    {
      label: "Documentation",
      icon: QuestionCircleIcon,
      onClick: () =>
        window.open("https://vital-records-docs.mnhs.io/index.html"),
    },
    props.birthRecords?.showSearch
      ? {
          label: "New Frostbite Docs",
          icon: ClipboardIcon,
          onClick: () =>
            window.open(
              "https://docs.google.com/document/d/1hyZ7DZhNBYLgmI8HEaUXy0ojtQe09hjwJY520LsZvBE/edit#heading=h.1ydujcykuabd",
            ),
        }
      : null,
  ]);

  const help = <PolarisNav.Section separator title="Support" items={helpNav} />;

  return (
    <PolarisNav location="/">
      {birthRecords}
      {deathRecords}
      {goldStarRecords}
      {vetsGraveRecords}
      {censusRecords}
      {collections}
      {findAids}
      {admin}
      {help}
    </PolarisNav>
  );
}
